const storageKey = "Vehicles-Client-ID";
let clientId: string;

export function getClientId() {
  if (clientId) return clientId;

  clientId = localStorage.getItem(storageKey);
  if (!clientId) {
    clientId = generateClientId();
    setClientId(clientId);
  }

  return clientId;
}

function generateClientId() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) => (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16));
}

function setClientId(value: string) {
  clientId = value;
  localStorage.setItem(storageKey, value);
}
