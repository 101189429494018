import { Carrier, Paintings } from "../entities";

const baseUrl = "https://pmdp-cdn.pages.dev";

export default class CdnClient {
  async request(route: string) {
    let path = `${baseUrl}/${route}`;
    let req = new Request(path);

    return await fetch(req);
  }

  async getCarrier() {
    let res = await this.request("carrier.json");
    if (!res.ok) throw `Failed to fetch carrier: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Carrier;
  }

  async getHistoricalCarrier() {
    let res = await this.request("historical.json");
    if (!res.ok) throw `Failed to fetch historical: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Carrier;
  }

  async getPaintings() {
    let res = await this.request("paintings.json");
    if (!res.ok) throw `Failed to fetch paintings: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Paintings;
  }

  /*async getOrderings() {
    let res = await this.request("orderings.json");
    if (!res.ok) throw `Failed to fetch orderings: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Orderings;
  }*/
}
