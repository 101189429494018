import * as Dom from "./dom";
import * as Data from "./data";
import * as Display from "./display";
import * as Events from "./events";
import { TractionType, Model, ListPair, Shift, Course, Trip } from "./entities";
import { tractionToHuman, tractionToSA, ageToHuman, idToTraction, getHhMm, offsetMidnight } from "./extensions";

export let darkenedElement: HTMLElement;
export let vehiclesPage: HTMLDivElement;
export let filtersEnabled: boolean;

export function addVehiclesPage(acPercentage: number, averageDelay: number) {
  vehiclesPage = (Dom.templates.vehiclesPage.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  vehiclesPage.querySelector<HTMLButtonElement>(".reset-filters").onclick = async () => await Events.resetFiltersClicked();
  //vehiclesPage.querySelector<HTMLButtonElement>(".buttons .notifications").onclick = async () => Display.addNotificationsView();
  //vehiclesPage.querySelector<HTMLButtonElement>(".buttons .contact").onclick = async () => Display.addContactView();
  //vehiclesPage.querySelector<HTMLButtonElement>(".buttons .faq").onclick = async () => Display.addFaqView();

  Array.from(vehiclesPage.querySelectorAll(".filter input") as NodeListOf<HTMLInputElement>).forEach(async (input) => {
    input.oninput = async () => await Events.filterInputsChanged();
  });

  vehiclesPage.querySelector<HTMLElement>(".ac-percentage").textContent = acPercentage.toString();
  vehiclesPage.querySelector<HTMLElement>(".average-delay").textContent = averageDelay.toString();
  if (Data.cachedSnapshot) {
    let parts = ageToHuman(Data.cachedSnapshot.age);
    vehiclesPage.querySelector<HTMLElement>(".data-age").textContent = (parts[0] as number).toString();
    vehiclesPage.querySelector<HTMLElement>(".data-age-unit").textContent = parts[1] as string;

    Display.addDataAge();
  }

  Dom.content.append(vehiclesPage);
}

export function resetDarkenedElement() {
  if (!darkenedElement) return;

  lightenElement(darkenedElement);
  darkenedElement = null;
}

export function addError(html: string) {
  Dom.dialogs.error.message.innerHTML = html;
  Dom.dialogs.error.self.showModal();
}

export function removeError() {
  Dom.dialogs.error.self.close();
}

export function addSuccess(htmlTitle: string, htmlMessage: string) {
  Dom.dialogs.success.title.innerHTML = htmlTitle;
  Dom.dialogs.success.message.innerHTML = htmlMessage;
  Dom.dialogs.success.self.showModal();
}

export function removeSuccess() {
  Dom.dialogs.success.self.close();
}

export function addVehicleView(line: string, heading: string, seznamAutobusu: string, provoz: string, delay: number, painting: string, from: string, to: string, lastStop: string, nextStop: string) {
  Dom.dialogs.vehicleView.tag.textContent = line;
  Dom.dialogs.vehicleView.heading.textContent = heading;
  Dom.dialogs.vehicleView.seznamAutobusu.href = seznamAutobusu;
  Dom.dialogs.vehicleView.provoz.href = provoz;
  if (delay == 0) {
    Dom.dialogs.vehicleView.delay.innerHTML = `<span class="safety">Spoj jede včas</span>`;
  } else {
    Dom.dialogs.vehicleView.delay.innerHTML = `+ <span class="danger"><b>${delay}</b> min.</span>`;
  }
  if (painting) {
    Dom.dialogs.vehicleView.paintingContainer.classList.remove("invisible");
    Dom.dialogs.vehicleView.painting.textContent = painting;
  } else {
    Dom.dialogs.vehicleView.paintingContainer.classList.add("invisible");
    Dom.dialogs.vehicleView.painting.textContent = "";
  }
  Dom.dialogs.vehicleView.path.textContent = `${from} > ${to}`;
  Dom.dialogs.vehicleView.lastStop.textContent = lastStop;
  Dom.dialogs.vehicleView.nextStop.textContent = nextStop ?? lastStop;
  Dom.dialogs.vehicleView.self.showModal();
  Dom.dialogs.vehicleView.seznamAutobusu.blur();

  Dom.dialogs.vehicleView.order.innerHTML = `Načítání`;
  Dom.dialogs.vehicleView.shift.innerHTML = `Načítání`;
  Dom.dialogs.vehicleView.connectionTableBody.innerHTML = "Načítání";
  while (Dom.dialogs.vehicleView.connectionTableBody.firstElementChild) Dom.dialogs.vehicleView.connectionTableBody.firstElementChild.remove();
}

export function addVehicleCourse(course: Course) {
  if (!course) {
    Dom.dialogs.vehicleView.order.innerHTML = `Chyba při načítání`;
    return;
  }

  Dom.dialogs.vehicleView.order.innerHTML = `Kurz: <code>${course.originalLine}/${course.order}</code>`;
}

export function addVehicleViewShift(shift: Shift) {
  if (!shift) {
    Dom.dialogs.vehicleView.shift.innerHTML = `Chyba při načítání`;
    return;
  }

  Dom.dialogs.vehicleView.shift.innerHTML = `Zátah: <code>${getHhMm(shift.endsAt)}</code>`;
}

export function addVehicleViewTrip(lastStopName: string, trip: Trip) {
  if (!trip) {
    Dom.dialogs.vehicleView.connectionTableBody.innerHTML = `Chyba při načítání`;
    return;
  } else {
    Dom.dialogs.vehicleView.connectionTableBody.innerHTML = "";
  }

  for (let departure of trip.departures) {
    let stopNameElement = document.createElement("td");
    stopNameElement.textContent = `${departure.stopName}${departure.isOnRequest ? " (Z)" : ""}`;

    let stopDepartureElement = document.createElement("td");
    stopDepartureElement.innerHTML = `<code>${offsetMidnight(getHhMm(departure.departuresAt))}</code>`;

    let tableRowElement = document.createElement("tr");
    if (departure.stopName == lastStopName) tableRowElement.classList.add("highlighted-stop");

    tableRowElement.appendChild(stopNameElement);
    tableRowElement.appendChild(stopDepartureElement);

    Dom.dialogs.vehicleView.connectionTableBody.appendChild(tableRowElement);
  }
}

export function removeVehicleView() {
  Dom.dialogs.vehicleView.self.close();
}

export function addTraction(type: TractionType, models: Model[]) {
  let vehicles = Data.vehicles.filter((x) => x.tractionType == type);

  console.info(`Adding traction for ${type} with ${models.length} models and ${vehicles.length} vehicles`);

  let tractionClone = (Dom.templates.traction.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

  (tractionClone.querySelector(".icon") as HTMLImageElement).src = `/assets/${TractionType[type].toString().toLowerCase()}.svg`;
  (tractionClone.querySelector(".title") as HTMLHeadingElement).textContent = `${tractionToHuman(type)} (${vehicles.length})`;
  let modelsDiv = tractionClone.querySelector(".models") as HTMLDivElement;

  let results: ListPair[] = [];

  for (let model of models) {
    let vehiclesOfModel = vehicles.filter((x) => model.registrationNumbers.includes(x.registrationNumber));
    if (vehiclesOfModel.length == 0) continue;

    vehiclesOfModel = vehiclesOfModel.sort((a, b) => {
      return a.registrationNumber - b.registrationNumber;
    });

    results.push({
      model,
      vehicles: vehiclesOfModel
    });
  }

  results = results.sort((a, b) => {
    return b.vehicles.length - a.vehicles.length;
  });

  let unassigned = vehicles.filter((original) => !results.some((result) => result.vehicles.some((vehicle) => vehicle.registrationNumber == original.registrationNumber)));
  if (unassigned && unassigned.length > 0) {
    console.info(`Unassigned for traction type ${type}`, unassigned);
  }

  let unusual = vehicles.filter((original) => {
    let vehicleType = idToTraction(Number(original.id.substring(0, 1)));
    let tractionType = original.lineMeta.tractionType;

    return vehicleType != tractionType;
  });

  if (unusual && unusual.length > 0) {
    console.info(`Unusual assign for traction type ${type}`, unusual);
  }

  for (let pair of results) {
    console.info(`Model ${pair.model.name} has ${pair.vehicles.length} vehicles on track`);

    let modelClone = (Dom.templates.model.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

    (modelClone.querySelector(".name") as HTMLHeadingElement).textContent = `${pair.model.name} (${pair.vehicles.length})`;
    let vehicleTags = modelClone.querySelector(".vehicle-tags") as HTMLDivElement;

    for (let vehicle of pair.vehicles) {
      let tractionType = idToTraction(Number(vehicle.id[0]));
      let vehicleTagClone = (Dom.templates.vehicleTag.content.cloneNode(true) as Element).firstElementChild as HTMLDivElement;

      vehicleTagClone.dataset.line = vehicle.lineMeta.name;
      vehicleTagClone.dataset.registrationNumber = vehicle.registrationNumber.toString();
      vehicleTagClone.textContent = vehicle.registrationNumber.toString();

      let painting = Data.paintings[vehicle.id];
      if (painting) {
        vehicleTagClone.classList.add("painting", painting.id);
      }

      vehicleTagClone.onclick = async () => {
        Display.darkenElement(vehicleTagClone);

        Display.addVehicleView(
          vehicle.lineMeta.name,
          pair.model.name,
          `https://seznam-autobusu.cz/seznam?evc=${vehicle.registrationNumber}&tractionId=${tractionToSA(tractionType)}&iddopravce=71`,
          `https://jizdnirady.pmdp.cz/provoz/?id=${vehicle.connectionId}`,
          vehicle.delayMinutes,
          painting?.name,
          vehicle.startName,
          vehicle.destinationName,
          vehicle.lastStopName,
          vehicle.nextStopName
        );

        Events.vehicleDialogOpened(vehicle);
      };

      vehicleTags.append(vehicleTagClone);
    }

    modelsDiv.append(modelClone);
  }

  vehiclesPage.querySelector(".vehicles-view").append(tractionClone);
}

export function setVehicleCount(value: number) {
  Dom.vehicleCount.textContent = ` (${value})`;
}

export function setFilterResults(value: number) {
  vehiclesPage.querySelector(".filter-results").textContent = ` (${value})`;
}

export function deleteFilterResults() {
  vehiclesPage.querySelector(".filter-results").textContent = "";
}

export function darkenElement(element: HTMLElement) {
  element.classList.add("dark");
  darkenedElement = element;
}

export function lightenElement(element: HTMLElement) {
  element.classList.remove("dark");
}

export function clearFilters() {
  if (filtersEnabled) {
    clearFilterInputs();
    deleteFilterResults();
    resetFilters();

    filtersEnabled = false;
  } else if (!filtersEnabled && Data.cachedSnapshot.isOld) {
    disableOldPreview();
  }
}

export function clearFilterInputs() {
  let inputs = vehiclesPage.querySelectorAll(".filter input") as NodeListOf<HTMLInputElement>;
  for (let input of Array.from(inputs)) input.value = "";
}

export function applyFilters() {
  filtersEnabled = true;

  let line = (vehiclesPage.querySelector("input[name='line-filter']") as HTMLInputElement).value;
  if (line == "") return;

  resetFilters();

  let lines = [line];
  if (line == "1") lines.push("1A", "1X", "1/2");
  else if (line == "2") lines.push("2A", "2X");
  else if (line == "4") lines.push("4A", "4X");

  let tags = Array.from(vehiclesPage.querySelectorAll(".vehicle-tags .tag") as NodeListOf<HTMLSpanElement>);
  let tagsOfInterest = tags.filter((tag) => {
    let datasetLine = tag.dataset.line;

    return !lines.includes(datasetLine);
  });

  dimElements(tagsOfInterest);
  setFilterResults(tags.length - tagsOfInterest.length);
}

export function resetFilters() {
  Array.from(vehiclesPage.querySelectorAll(".vehicle-tags .tag.dim") as NodeListOf<HTMLSpanElement>).forEach((tag) => {
    tag.classList.remove("dim");
  });
}

export function dimElements(elements: HTMLElement[]) {
  for (let element of elements) dimElement(element);
}

export function dimElement(element: HTMLElement) {
  element.classList.add("dim");
}

export function disableOldPreview() {
  let url = new URL(window.location.href);
  url.search = "";

  window.location.href = url.toString();
}

export function addLoading() {
  Dom.status.classList.remove("invisible");
}

export function removeLoading() {
  Dom.status.classList.add("invisible");
}

export function setLoadingMessage(msg: string) {
  Dom.status.textContent = msg;
}

export function addDisclaimer() {
  Dom.disclaimer.classList.remove("invisible");
}

export function removeDisclaimer() {
  Dom.disclaimer.classList.add("invisible");
}

export function addDataAge() {
  vehiclesPage.querySelector(".data-age-container").classList.remove("invisible");
}

export function removeDataAge() {
  vehiclesPage.querySelector(".data-age-container").classList.add("invisible");
}

export function addNotificationsView() {
  darkenElement(document.querySelector<HTMLButtonElement>(".buttons .notifications"));
  Dom.dialogs.notificationsView.self.showModal();
}

export function removeNotificationsView() {
  Dom.dialogs.notificationsView.self.close();
}

export function addContactView() {
  darkenElement(document.querySelector<HTMLButtonElement>(".buttons .contact"));
  Dom.dialogs.contactView.form.self.reset();
  Dom.dialogs.contactView.form.contentLabel.textContent = Dom.dialogs.contactView.form.contentLabel.dataset.default;
  Dom.dialogs.contactView.form.content.oninput = Events.contactContentInput;
  Dom.dialogs.contactView.form.self.onsubmit = Events.sendContactsClicked;
  Dom.dialogs.contactView.self.showModal();
}

export function removeContactView() {
  Dom.dialogs.contactView.self.close();
}

export function addFaqView() {
  darkenElement(document.querySelector<HTMLButtonElement>(".buttons .faq"));
  Dom.dialogs.faqView.self.showModal();
}

export function removeFaqView() {
  Dom.dialogs.faqView.self.close();
}
