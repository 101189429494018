import * as Dom from "./dom";
import * as Display from "./display";
import * as Data from "./data";
import { Vehicle } from "./entities";

Dom.dialogs.error.close.onclick = async () => {
  Display.removeError();
};

Dom.dialogs.success.close.onclick = async () => {
  Display.removeSuccess();
};

Dom.dialogs.vehicleView.close.onclick = async () => {
  Display.removeVehicleView();
};

Dom.dialogs.vehicleView.self.onclose = async () => {
  Display.resetDarkenedElement();
};

Dom.dialogs.notificationsView.close.onclick = async () => {
  Display.removeNotificationsView();
};

Dom.dialogs.notificationsView.self.onclose = async () => {
  Display.resetDarkenedElement();
};

Dom.dialogs.contactView.close.onclick = async () => {
  Display.removeContactView();
};

Dom.dialogs.contactView.self.onclose = async () => {
  Display.resetDarkenedElement();
};

Dom.dialogs.faqView.close.onclick = async () => {
  Display.removeFaqView();
};

Dom.dialogs.faqView.self.onclose = async () => {
  Display.resetDarkenedElement();
};

export async function resetFiltersClicked() {
  Display.clearFilters();
}

export async function filterInputsChanged() {
  Display.applyFilters();
}

export async function vehicleDialogOpened(vehicle: Vehicle) {
  console.info("[Vehicle] Dialog opened");

  const tripId = Math.abs(vehicle.connectionId);

  let coursePromise = Data.fleetoraClient.getCourse(vehicle.id);
  let shiftPromise = Data.fleetoraClient.getShift(vehicle.id);
  let tripPromise = Data.fleetoraClient.getTrip(tripId);

  try {
    let course = await coursePromise;

    Display.addVehicleCourse(course);
  } catch (err) {
    Display.addVehicleCourse(null);

    console.error("[Vehcicle] Failed to load order", err);
  }

  try {
    let shift = await shiftPromise;

    Display.addVehicleViewShift(shift);
  } catch (err) {
    Display.addVehicleViewShift(null);

    console.error("[Vehcicle] Failed to load shift", err);
  }

  try {
    let trip = await tripPromise;

    Display.addVehicleViewTrip(vehicle.lastStopName, trip);
  } catch (err) {
    Display.addVehicleViewTrip(vehicle.lastStopName, null);

    console.error("[Vehcicle] Failed to load trip", err);
  }
}

export async function contactContentInput(evt: Event) {
  let remaining = 1024 - Dom.dialogs.contactView.form.content.value.length;

  Dom.dialogs.contactView.form.contentLabel.textContent = `Obsah sdělení (zbývá ${remaining} znaků)`;

  if (remaining <= 0) {
    return evt.preventDefault();
  }
}

export async function sendContactsClicked(evt: SubmitEvent) {
  evt.preventDefault();

  let formData = new FormData(Dom.dialogs.contactView.form.self);
  if (!(formData.get("file") as File)?.name) {
    formData.delete("file");
  }

  try {
    Dom.dialogs.contactView.form.submit.disabled = true;

    await Data.fleetoraClient.sendFeedback(formData);
    Dom.dialogs.contactView.form.self.reset();

    Display.addSuccess("Formulář odeslán", "Odpověď bude v brzké době zaslána na tvůj email.");
    Dom.dialogs.success.self.addEventListener("close", () => {
      Dom.dialogs.contactView.self.close();
    });

    console.info("[Events] Submitted feedback");
  } catch (err) {
    console.error("[Events] Failed to submit contact form", err);

    Display.addError(`Selhalo odesílání kontaktního formuláře:<br>${err}`);
  }

  Dom.dialogs.contactView.form.submit.disabled = false;
}

let dialogs = Array.from(document.querySelectorAll<HTMLDialogElement>("dialog"));
for (let dialog of dialogs) {
  dialog.onclick = async (evt: MouseEvent) => {
    let element = evt.target as HTMLDialogElement;
    if (element.nodeName == "DIALOG") element.close();
  };
}
