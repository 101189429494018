export enum TractionType {
  Tram = "Tram",
  Trolleybus = "Trolleybus",
  Bus = "Bus"
}

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}

export interface Carrier {
  [key: string]: Model[];
}

export interface Paintings {
  [key: string]: Painting;
}

/*export interface Orderings {
  [key: number]: string;
}*/

export interface Painting {
  id: string;
  name: string;
}

export interface Model {
  name: string;
  registrationNumbers: number[];
}

export interface CachedSnapshot {
  age: number;
  date: Date;
  dayOfWeek: DayOfWeek;
  isOld: boolean;
  vehicles: Vehicle[];
}

export interface Vehicle {
  id: string;
  registrationNumber: number;
  tractionType: TractionType;
  lineMeta: LineMeta;
  connectionId: number;
  delayMinutes: number;
  lastStopIndex: number;
  lastStopName: string;
  nextStopIndex: number;
  nextStopName: string;
  startName: string;
  destinationName: string;
  latitude: number;
  longitude: number;
  azimuth?: number;
  timestamp: string;
  isAirConditioned: boolean;
  quickMessageIds: number[];
}

export interface LineMeta {
  name: string;
  number: number;
  tractionType: TractionType;
  isBarrierFree: boolean;
  isNightConnection: boolean;
}

export interface ListPair {
  model: Model;
  vehicles: Vehicle[];
}

export interface Trip {
  id: number;
  line: string;
  departures: Departure[];
}

export interface Departure {
  stopName: string;
  departuresAt: string; //TimeSpan hh:mm:ss
  isOnRequest: boolean;
}

export interface Course {
  originalLine: string;
  order: number;
}

export interface Shift {
  endsAt?: string;
}

export interface PushRegistration {
  endpointUrl: string;
  auth: string;
  p256dh: string;
}

export interface PushUnregistration {
  endpointUrl: string;
}
