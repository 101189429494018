import { pascalToCamel, fleetoraRewriteProperties, toDayOfWeek, fleetoraAddProperties } from "../extensions";
import { Vehicle, CachedSnapshot,  PushRegistration, PushUnregistration, Shift, Course, Trip } from "../entities";
import * as Session from "../session";

const baseUrl = "https://fleetora-service.akac.dev";

export default class FleetoraClient {
  apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  async request(method: string, route: string, body: unknown = null) {
    let headers = new Headers();
    headers.set("Authorization", this.apiKey);
    headers.set("Client-ID", Session.getClientId());
    if (body) headers.set("Content-Type", "application/json; charset=utf-8");

    let path = `${baseUrl}/api/${route}`;
    let req = new Request(path, {
      method: method,
      body: body ? JSON.stringify(body) : null,
      headers: headers,
      credentials: "include"
    });

    return await fetch(req);
  }

  async multipartRequest(route: string, body: FormData) {
    let headers = new Headers();
    headers.set("Authorization", this.apiKey);
    headers.set("Client-ID", Session.getClientId());

    let path = `${baseUrl}/api/${route}`;
    let req = new Request(path, {
      method: "POST",
      body: body,
      headers: headers,
      credentials: "include"
    });

    return await fetch(req);
  }

  async getTraffic() {
    let res = await this.request("GET", "provoz");
    if (!res.ok) throw `Failed to fetch cached traffic: ${res.status} => ${await res.text()}`;

    let data = fleetoraAddProperties(fleetoraRewriteProperties(pascalToCamel(await res.json()))) as Vehicle[];

    let snapshot: CachedSnapshot = {
      age: Number(res.headers.get("Age")),
      date: new Date(res.headers.get("Date")),
      dayOfWeek: toDayOfWeek(res.headers.get("Day-Of-Week")),
      isOld: false,
      vehicles: data
    };

    return snapshot;
  }

  async getOldTraffic(date: Date) {
    let res = await this.request("GET", `provoz/old?dateTime=${date.toISOString()}`);
    if (!res.ok) throw `Failed to fetch traffic for ${date}: ${res.status} => ${await res.text()}`;

    let data = fleetoraAddProperties(fleetoraRewriteProperties(pascalToCamel(await res.json()))) as Vehicle[];

    let snapshot: CachedSnapshot = {
      age: Number(res.headers.get("Age")),
      date: new Date(res.headers.get("Date")),
      isOld: true,
      dayOfWeek: toDayOfWeek(res.headers.get("Day-Of-Week")),
      vehicles: data
    };

    return snapshot;
  }

  async getTrip(tripId: number) {
    let res = await this.request("POST", `gtfs/trip`, tripId);
    if (!res.ok) throw `Failed to fetch trip ${tripId}: ${res.status} => ${await res.text()}`;

    return pascalToCamel(await res.json()) as Trip;
  }

  async getCourse(vehicleId: string) {
    let res = await this.request("POST", `provoz/course`, vehicleId);
    if (!res.ok) throw `Failed to fetch order of ${vehicleId}: ${res.status} => ${await res.text()}`;

    return pascalToCamel(await res.json()) as Course;
  }

  async getShift(vehicleId: string) {
    let res = await this.request("POST", `provoz/shift`, vehicleId);
    if (!res.ok) throw `Failed to fetch order of ${vehicleId}: ${res.status} => ${await res.text()}`;

    return pascalToCamel(await res.json()) as Shift;
  }

  async subscribePush(registration: PushRegistration) {
    let res = await this.request("POST", "push/subscribe", registration);
    if (res.status != 202) throw `Failed to subscribe push: ${res.status} => ${await res.text()}`;
  }

  async unsubscribePush(unregistration: PushUnregistration) {
    let res = await this.request("DELETE", "push/unsubscribe", unregistration);
    if (res.status != 202) throw `Failed to unsubscribe push: ${res.status} => ${await res.text()}`;
  }

  async addTracker(vehicleId: string) {
    let res = await this.request("POST", `tracker/add?vehicleId=${vehicleId}`);
    if (res.status != 202) throw `Failed to add tracker: ${res.status} => ${await res.text()}`;
  }

  async removeTracker(vehicleId: string) {
    let res = await this.request("DELETE", `tracker/remove?vehicleId=${vehicleId}`);
    if (res.status != 202) throw `Failed to remove tracker: ${res.status} => ${await res.text()}`;
  }

  async getTrackers() {
    let res = await this.request("GET", `tracker/all`);
    if (!res.ok) throw `Failed to get trackers: ${res.status} => ${await res.text()}`;

    return pascalToCamel(await res.json()) as string[];
  }

  async sendFeedback(formData: FormData) {
    let res = await this.multipartRequest("feedback", formData);
    if (res.status != 202) throw `Failed to submit feedback: ${res.status} => ${await res.text()}`;
  }
}
