import * as Dom from "./dom";
import * as Display from "./display";
import * as Events from "./events";
import * as Data from "./data";
import * as Session from "./session";
import * as Service from "./service";
import { DayOfWeek, TractionType } from "./entities";
import { uppercaseFirst } from "./extensions";

declare global {
  interface Window {
    fleetoraClient: unknown;

    Dom: unknown;
    Display: unknown;
    Events: unknown;
    Data: unknown;
    Session: unknown;
    Service: unknown;
  }
}

let urlParameters = new URLSearchParams(window.location.search);

async function main() {
  Display.addLoading();

  let preview;
  if (urlParameters.has("preview")) {
    preview = new Date(urlParameters.get("preview"));

    console.info("Found preview parameter", preview);
  }

  try {
    await Data.load(preview);

    if (Data.cachedSnapshot) {
      console.info(`Cached snapshot is ${Data.cachedSnapshot.age} seconds old, day of week is ${DayOfWeek[Data.cachedSnapshot.dayOfWeek]}`);
    }
  } catch (err) {
    console.error("Failed to load data", err);

    Display.setLoadingMessage("❌ Selhalo načítání dat, zkus to znovu později");
    return;
  }

  Display.removeLoading();

  let acVehicles = Data.vehicles.filter((x) => x.isAirConditioned);
  let trackVehicles = Data.vehicles.filter((x) => x.nextStopName);
  let delayedVehicles = trackVehicles.filter((x) => x.delayMinutes && x.nextStopName);
  let totalDelay = delayedVehicles.map((x) => x.delayMinutes).reduce((result, a) => result + a, 0) * 60;

  let acPercentage = Math.round((acVehicles.length / Data.vehicles.length) * 100);
  let averageDelay = Math.round(totalDelay / trackVehicles.length);

  Display.addVehiclesPage(acPercentage, averageDelay);
  Display.setVehicleCount(Data.vehicles.length);

  for (let [key, value] of Object.entries(Data.carrier)) {
    let tractionType = TractionType[uppercaseFirst(key) as keyof typeof TractionType];

    Display.addTraction(tractionType, value);
  }

  Display.addDisclaimer();
}

function registerGlobals() {
  window.fleetoraClient = Data.fleetoraClient;

  window.Dom = Dom;
  window.Display = Display;
  window.Events = Events;
  window.Data = Data;
  window.Session = Session;
  window.Service = Service;
}

registerGlobals();
main();
