import { pascalToCamel, fleetoraRewriteProperties } from "../extensions";
import { Vehicle } from "../entities";

const baseUrl = "https://signalr.akac.workers.dev";

export default class SignalRClient {
  async request(route: string) {
    let path = `${baseUrl}/${route}`;
    let req = new Request(path);

    return await fetch(req);
  }

  async getTraffic() {
    let res = await this.request("traffic");
    if (!res.ok) throw `Failed to fetch traffic: ${res.status} => ${await res.text()}`;

    return fleetoraRewriteProperties(pascalToCamel(await res.json())) as Vehicle[];
  }
}
