import { DayOfWeek, TractionType } from "./entities";

type JsonObject = { [key: string]: any };

const tractionHumanMap = new Map<TractionType, string>([
  [TractionType.Tram, "Tramvaje"],
  [TractionType.Trolleybus, "Trolejbusy"],
  [TractionType.Bus, "Autobusy"]
]);

const idTractionMap = new Map<number, TractionType>([
  [1, TractionType.Tram],
  [2, TractionType.Trolleybus],
  [3, TractionType.Bus]
]);

const tractionSAMap = new Map<TractionType, number>([
  [TractionType.Tram, 1],
  [TractionType.Trolleybus, 3],
  [TractionType.Bus, 5]
]);

export function getRegistrationNumber(id: string) {
  return Number(id.slice(1));
}

export function getTractiontype(id: string) {
  return idToTraction(Number(id.charAt(0)));
}

export function uppercaseFirst(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function idToTraction(value: number) {
  return idTractionMap.get(value);
}

export function tractionToHuman(type: TractionType) {
  return tractionHumanMap.get(type);
}

export function tractionToSA(type: TractionType) {
  return tractionSAMap.get(type);
}

export function pascalToCamel(obj: JsonObject): JsonObject {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => pascalToCamel(item));
  }

  return Object.keys(obj).reduce((acc: JsonObject, key: string) => {
    let camelKey = key.charAt(0).toLowerCase() + key.slice(1);
    acc[camelKey] = pascalToCamel(obj[key]);
    return acc;
  }, {});
}

export function fleetoraAddProperties(obj: JsonObject) {
  for (const innerObj of obj as any[]) {
    innerObj["registrationNumber"] = getRegistrationNumber(innerObj["id"]);
    innerObj["tractionType"] = getTractiontype(innerObj["id"]);
  }

  return obj;
}

export function fleetoraRewriteProperties(obj: JsonObject): JsonObject {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  let renameMap: { [key: string]: string } = {
    vehicleNumber: "id",
    delayMin: "delayMinutes"
  };

  if (Array.isArray(obj)) {
    return obj.map((item) => fleetoraRewriteProperties(item));
  }

  return Object.keys(obj).reduce((acc: JsonObject, key: string) => {
    let newKey = renameMap[key] || key;
    acc[newKey] = fleetoraRewriteProperties(obj[key]);
    return acc;
  }, {});
}

export function ageToHuman(seconds: number) {
  if (seconds < 60) {
    return [seconds, "s"];
  } else if (seconds < 3600) {
    let minutes = Math.floor(seconds / 60);
    return [minutes, "min."];
  } else if (seconds < 86400) {
    let hours = Math.floor(seconds / 3600);
    return [hours, "hod."];
  } else {
    let days = Math.floor(seconds / 86400);
    return [days, days == 1 ? "den" : days >= 2 && days <= 4 ? "dny" : "dnů"];
  }
}

export function toDayOfWeek(day: string | null): DayOfWeek | null {
  if (!day) return null;

  const normalizedDay = day.trim().toLowerCase();
  for (const dayEnumValue of Object.values(DayOfWeek)) {
    if (dayEnumValue.toLowerCase() === normalizedDay) {
      return dayEnumValue as DayOfWeek;
    }
  }

  return null;
}

export function getHhMm(timeSpan: string) {
  return timeSpan.slice(0, 5);
}

export function offsetMidnight(timeSpan: string) {
  const [hours, minutes] = timeSpan.split(":").map(Number);

  if (hours >= 24) {
    return `${hours - 24}:${minutes}`;
  }

  return timeSpan;
}
