import * as Constants from "./constants";

export function getAvailability() {
  return "serviceWorker" in navigator;
}

export async function isRegistered() {
  let registration = await navigator.serviceWorker.getRegistration();

  return !!registration;
}

export async function isSubscribed() {
  let sw = await navigator.serviceWorker.ready;

  let sub = await sw.pushManager.getSubscription();
  return !!sub;
}

export async function register() {
  let url = new URL("../service-worker/sw.ts", import.meta.url);
  let sw = await navigator.serviceWorker.register(url, {
    type: "module"
  });

  console.info("[Service]", "Registered", sw);
}

export async function unregister() {
  let registration = await navigator.serviceWorker.getRegistration();

  registration.unregister();
  console.info("[Service]", "Unregistered", registration);
}

export async function subscribe() {
  let sw = await navigator.serviceWorker.ready;

  let push;
  try {
    push = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: Constants.publicPushKey
    });
  } catch (err) {
    if (err instanceof DOMException && err.name == "NotAllowedError") {
      console.error("[Service]", "User denied permission to use the Push API");
    } else {
      console.error("[Service]", "Error while subscribing", err);
    }

    throw err;
  }

  console.info("[Service]", "Created a push subscription");
  console.debug(JSON.stringify(push, null, 2));

  return push;
}

export async function unsubscribe() {
  let sw = await navigator.serviceWorker.ready;

  let sub = await sw.pushManager.getSubscription();
  if (!sub) throw `No push subscription to unsubscribe`;

  console.info("[Service]", "Subscription", sub);

  try {
    await sub.unsubscribe();

    console.info("[Service]", "Unsubscribed the push subscription");
  } catch (err) {
    console.info("[Service]", "Failed to unsubscribe", err);
    throw err;
  }
}
